import Router from "vue-router"
import Vue from "vue"
Vue.use(Router)

const createRouter=()=>new Router({
    mode:"history",
    routes:[
        {
          path: "/", // 首页
          name: "home",
          component: () => import("@/views/home/index.vue")
        },
        {
          path: "/ly", // 临渊
          name: "linyuan",
          component: () => import("@/views/linyuan/index.vue")
        },
        {
          path: "/xh", // 仙后
          name: "xianhou",
          component: () => import("@/views/xianhou/index.vue")
        },
        {
          path: "/qsr", // 趣输入
          name: "qushouru",
          component: () => import("@/views/qushouru/index.vue")
        },
        {
          path: "/bld", // 豹来电
          name: "baolaidian",
          component: () => import("@/views/baolaidian/index.vue")
        },
        {
          path: "/xld", // x来电
          name: "xlaidian",
          component: () => import("@/views/xlaidian/index.vue")
        },
        {
          path: "/tt", // 泰坦
          name: "taitan",
          component: () => import("@/views/taitan/index.vue")
        },
        {
          path: "/zjy", // 智聚云
          name: "zhijuyun",
          component: () => import("@/views/zhijuyun/index.vue")
        },
        {
          path: "/tq", // 天琴
          name: "tianqin",
          component: () => import("@/views/tianqin/index.vue")
        },
        {
          path: "/newsList", // 新闻列表
          name: "newsList",
          component: () => import("@/views/news/index.vue")
        },
        {
          path: "/newsDetail/:id", // 新闻详情
          name: "newsDetail",
          component: () => import("@/views/news/Detail.vue")
        },
        {
          path: "/contact", // 联系我们
          name: "contact",
          component: () => import("@/views/contact/index.vue")
        },
        {
          path: "/about", // 公司简介
          name: "about",
          component: () => import("@/views/about/index.vue")
        },
        {
          path: "/ss", // 射手
          name: "sheshou",
          component: () => import("@/views/sheshou/index.vue")
        },
        {
          path: "/qfy", // 千帆云
          name: "qianfanyun",
          component: () => import("@/views/qianfanyun/index.vue")
        },
        {
          path: "/ylb", // 引力播
          name: "ylb",
          component: () => import("@/views/ylb/index.vue")
        },
        {
          path: "/juliang", // 巨量引擎
          name: "juliang",
          component: () => import("@/views/juliang/index.vue")
        },
        {
          path: "/cili", // 磁力引擎
          name: "cili",
          component: () => import("@/views/cili/index.vue")
        },
        // 泛娱乐场景营销平台
        // {
        //   path: "/fyl", 
        //   name: "fyl",
        //   component: () => import("@/views/fyl/index.vue")
        // },
        {
          path: "/kxxxd", // 游戏
          name: "kxxxd",
          component: () => import("@/views/kxxxd/index.vue")
        },
    ],
    scrollBehavior() {
      return { x: 0, y: 0 };
    }
})

export  {createRouter};
