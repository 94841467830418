// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js??ref--6-oneOf-1-1!../node_modules/_vue-loader@15.9.5@vue-loader/lib/loaders/stylePostLoader.js!../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--6-oneOf-1-2!../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--6-oneOf-1-3!./assets/font/font.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/images/android.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#app{font-family:PingFangSC-Regular,PingFang SC;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#2c3e50}body,html{background:#fff}*,body,html{margin:0;padding:0}*{font-size:16px;list-style:none;text-decoration:none;font-family:PingFangSC-Regular,PingFang SC}.down{width:240px;margin:50px auto 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.down a{display:block;width:240px;height:60px;background:#f1f1f1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.down a div{margin:auto;height:23px;color:#222}.down a div b{width:18px;height:23px;margin-right:8px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.down a div b,.down a div b span{display:inline-block;vertical-align:middle}.down:hover{background:#eef6ff}@media (min-width:1500px){.banner .swiper-pagination{bottom:130px!important}}", ""]);
// Exports
module.exports = exports;
