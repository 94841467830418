//本文件的用途是创建vue实例
import { createRouter } from "./plugins/router/index.js"
import Vue from "vue"
import App from "./App.vue"
import VueI18n from 'vue-i18n';
import headMixin from './assets/head';
Vue.mixin(headMixin);



Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('@/assets/languages/zh.json'),
    'en': require('@/assets/languages/en.json')
  }
})

export function createApp() {
    const router = createRouter();
    const app = new Vue({
        i18n,
        router,
        render: h => h(App)
    })

    return { app, router }
}
