<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style>
@import "./assets/font/font.css";
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html,
body {
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 1);
}
* {
  font-size: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: PingFangSC-Regular, PingFang SC;
}
.down {
  width: 240px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
}
.down a {
  display: block;
  width: 240px;
  height: 60px;
  background: rgba(241, 241, 241, 1);
  display: flex;
  align-items: center;
}
.down a div {
  margin: auto;
  height: 23px;
  color: rgba(34, 34, 34, 1);
}
.down a div b {
  display: inline-block;
  width: 18px;
  height: 23px;
  margin-right: 8px;
  vertical-align: middle;
  background: url(./assets/images/android.png);
}
.down a div b span {
  display: inline-block;
  vertical-align: middle;
}
.down:hover {
  background: rgba(238, 246, 255, 1);
}

@media (min-width: 1500px) {
  .banner .swiper-pagination {
    bottom: 130px !important;
  }
}
</style>
